@keyframes Pump {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(2);
    }
    100% {
        transform: scale(1);
    }
}
.Fallback {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    & > img {
        width: 48%;
        max-width: 400px;
        animation: Pump 2s ease-in-out infinite;
    }
}
