a {
    text-decoration: none;
    color: var(--secondary);
}
h1 {
    font-size: 5.3rem;
}
h2 {
    font-size: 4rem;
}
h3 {
    font-size: 3rem;
}
h4 {
    font-size: 2.5rem;
}
h5 {
    font-size: 1.7rem;
}
h6 {
    font-size: 1.2rem;
}
p {
    font-size: 1.8rem;
    font-family: var(--font2);
}
