@import "~fonts/CoreSansC/stylesheet.css"; /* Core Sans C */
@font-face {
    font-family: menlo;
    font-style: normal;
    font-weight: 400;
    src: local("Menlo"), url(https://fonts.cdnfonts.com/s/13494/Menlo-Regular.woff) format("woff");
} /* Menlo */

:root {
    --font1: "Core Sans C", sans-serif;
    --font2: "Menlo", sans-serif;
    font-size: 12px;
}
