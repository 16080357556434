:root {
    --primary: #011728;
    --primary-shade: #122136;

    --dark: #25292e;
    --grey: #596069;
    --light: #fbf6f0;
    --light-grey: #bfc0c4;

    --white: #ffffff;
    --black: #000000;

    --secondary: #82b3fd;
    --secondary-shade: #253b5a;

    --pink: #f9aeae;
    --red: #f24e1e;
    --blue: #3884f7;
    --yellow: #f4be4f;
    --green: #61c454;
    --green-shade: #2ac07a;
    --purple: #cb9ff9;
}
