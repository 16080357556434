@import "./_fonts.scss";
@import "./_colors.scss";
@import "./_variables.scss";
@import "./_typography.scss";

html,
body,
#root,
.App,
.content {
    height: 100%;
    width: 100%;
    font-family: var(--font1);
}
* {
    box-sizing: border-box;
}
html,
body {
    scroll-behavior: smooth;
    overflow-x: hidden;
}
body {
    margin: 0;
    padding: 0;
    background-color: var(--primary);
    color: var(--white);
}

.App {
    display: flex;
    flex-direction: column;
    position: relative;
}
section {
    display: flex;
    margin: 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: var(--max-width);
}
.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
/*-------------------------- colors --------------------------*/

.secondary {
    color: var(--secondary) !important;
}
.green {
    color: var(--green) !important;
}
.purple {
    color: var(--purple) !important;
}
.blue {
    color: var(--blue) !important;
}
.yellow {
    color: var(--yellow) !important;
}
.red {
    color: var(--red) !important;
}

/*-------------------------- grid --------------------------*/

.w50 {
    width: 49% !important;
}
@media (max-width: 1060px) {
    .w50 {
        width: 100% !important;
    }
}

/*-------------------------- scrollbar --------------------------*/
::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--primary);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--dark);
    border-radius: 2em;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--primary-shade);
}
